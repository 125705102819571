import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import SectorSection from '../components/SectorSection';
import SEO from '../components/SEO';
import Footer from '../sections/Footer';

const pageQuery = graphql`
  {
    datoCmsSectorsPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    allDatoCmsPortfolioCategory(sort: { fields: position }) {
      nodes {
        id
        slug
        categoryName
        categoryDescription
        featuredImages {
          url
        }
      }
    }
  }
`;

export default function SectorsPage() {
  const data = useStaticQuery(pageQuery);
  const { seoMetaTags } = data.datoCmsSectorsPage;
  const { nodes: categories } = data.allDatoCmsPortfolioCategory;

  return (
    <div
      className="bg-blue-light"
      // style={{
      //   height: '100vh',
      //   overflowY: 'scroll',
      //   scrollSnapType: 'y mandatory',
      // }}
    >
      <SEO meta={seoMetaTags} />
      <div className="pt-16">
        {categories.map((item, index) => (
          <SectorSection key={item.id} alt={index % 2 === 0} content={item} />
        ))}
      </div>
      <Footer />
    </div>
  );
}
