import { gatsby, navigate } from 'gatsby';
import React from 'react';
import SquareMasonry from './SquareMasonry';
import Title from './Title';
import { formatBranding } from '../utils/helpers';

export default function SectorSection({ content, alt }) {
  const {
    id,
    categoryName,
    categoryDescription,
    featuredImages,
    slug,
  } = content;

  return (
    <div
      className={`py-16 md:py-32 px-3 ${alt || 'bg-white'}`}
      style={{ scrollSnapAlign: 'start' }}
    >
      <div className="container grid grid-cols-5 gap-5 md:gap-20">
        <SquareMasonry
          images={featuredImages}
          extraKey={id}
          classNames={`col-span-5 md:col-span-2 h-64 md:h-auto ${
            alt ? 'order-last md:order-2' : 'order-last md:order-1'
          }`}
        />

        <div
          className={`col-span-5 md:col-span-3 ${alt ? 'order-1' : 'order-2'}`}
        >
          <Title text={categoryName} />
          <div
            data-sal="slide-up"
            className="py-4 tracking-wider richText"
            dangerouslySetInnerHTML={{
              __html: formatBranding(categoryDescription),
            }}
          />
          <button
            onClick={() => {
              navigate(`/portfolio?type=${slug}`);
            }}
            className="mt-10 text-sm uppercase tracking-wider font-heading text-orange-theme py-2 px-4 pl-0 transform hover:scale-x-105 hover:scale-y-100 transition duration-300 ease-out inline-flex"
          >
            View other {slug} projects
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 30 30 "
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
